import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";
import { Constants } from "../../../constants/constants";

// Components
import { AlertingProfileComponent } from "./alerting-profile/alerting-profile.component";
import { AlertingProfilesListComponent } from "./alerting-profiles-list/alerting-profiles-list.component";
import { AlertingProfileFormComponent } from "./alerting-profile-form/alerting-profile-form.component";

// Resolver
import { AlertingProfilesListResolverService } from "./alerting-profiles-list/alerting-profiles-list-resolver.service";

const eventsManagementRoutes: Routes = [
    {
        path: Constants.urls.configuration.eventsManagement + "/new",
        component: AlertingProfileFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.ADDING_NEW_EVENTS_PROFILE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/O4CRgw"
                }
            ]
        }
    },
    {
        path: Constants.urls.configuration.eventsManagement + "/:id/:action",
        component: AlertingProfileFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.eventsManagement,
        component: AlertingProfilesListComponent,
        canActivate: [AuthGuard],
        resolve: {
            alerting_profile: AlertingProfilesListResolverService
        },
        children: [
            {
                path: ":id",
                component: AlertingProfileComponent
            }
        ],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CONFIG_EVENTS_PROFILES",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/CICRgw"
                },
                {
                    menu: "HELP_MENU.ADDING_NEW_EVENTS_PROFILE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/O4CRgw"
                },
                {
                    menu: "HELP_MENU.EDITING_EVENTS_PROFILE_RULES",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/VICRgw"
                },
                {
                    menu: "HELP_MENU.VIEWING_EXISTING_EVENTS_PROFILES",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/goCTgw"
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(eventsManagementRoutes)],
    exports: [RouterModule]
})
export class EventsManagementRouting {}
