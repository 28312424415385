<content-loading-animation *ngIf="loadingDetails"></content-loading-animation>
<!-- Details Panel -->
<div class="details-panel" *ngIf="!loadingDetails">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ alertProfile.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon></button
                >{{ alertProfile.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- Mobile Action Buttons -->
            <div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="md"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <button
                                *ngIf="!alertProfile.default"
                                (click)="editAlertProfile(alertProfile.id)"
                                class="text-primary"
                                title="{{ 'EDIT' | translate }}"
                                mat-menu-item
                            >
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button (click)="cloneAlertProfile(alertProfile.id)" class="text-primary" mat-menu-item>
                                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "CLONE" | translate }}
                            </button>
                            <button
                                *ngIf="!alertProfile.default"
                                (click)="deleteAlertProfile()"
                                class="text-danger"
                                title="{{ 'DELETE' | translate }}"
                                mat-menu-item
                            >
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <!-- Action Buttons -->
            <div class="d-none d-md-block float-end">
                <div class="btn-group mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        *ngIf="!alertProfile.default"
                        (click)="editAlertProfile(alertProfile.id)"
                        title="{{ 'EDIT' | translate }}"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary" (click)="cloneAlertProfile(alertProfile.id)">
                        <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "CLONE" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        *ngIf="!alertProfile.default"
                        (click)="deleteAlertProfile()"
                        title="{{ 'DELETE' | translate }}"
                    >
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions">
        <div class="row" tourAnchor="thirdAPListAnchor">
            <div class="col">
                <!-- Rules -->
                <ngb-accordion
                    #rulesAccordion="ngbAccordion"
                    activeIds="ngb-panel-rules"
                    class="no-padding"
                    *ngIf="alertProfile.alertingRules && alertProfile.alertingRules.length > 0"
                >
                    <ngb-panel id="ngb-panel-rules">
                        <ng-template ngbPanelTitle>
                            <h3 title="{{ 'ALERT_RULES' | translate }}">
                                {{ "ALERT_RULES" | translate
                                }}<span class="ms-1 badge badge-primary">
                                    {{ alertProfile.alertingRules.length }}
                                </span>
                            </h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-table-list
                                [tableName]="'alert_rules'"
                                [displayTableName]="'RULES' | translate"
                                [data]="alert_rules$ | async"
                                [(tableSchema)]="tableColumnsSchema"
                                [hoverable]="false"
                                [selectable]="false"
                                [showReport]="false"
                                [autoRows]="false"
                                [showSelectionCheckbox]="false"
                                [showExpandButton]="true"
                                [inAccordion]="true"
                            >
                            </app-table-list>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>
