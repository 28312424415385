import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";
import { AlertingProfile } from "../events-management";
import { EventsManagementService } from "../events-management.service";

@Injectable({
    providedIn: "root"
})
export class AlertingProfilesListResolverService implements Resolve<AlertingProfile[]> {
    constructor(private ems: EventsManagementService) {}

    resolve(): Observable<AlertingProfile[]> | Observable<never> {
        return new Observable((observe: Subscriber<AlertingProfile[]>) => {
            this.ems.refreshAlertingProfiles().subscribe((alertingProfiles: AlertingProfile[]) => {
                observe.next(alertingProfiles);
                observe.complete();
            });
        });
    }
}
