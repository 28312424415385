import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { MatMenuModule } from "@angular/material/menu";
import { NgxSliderModule } from "ngx-slider-v2";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { AlertingProfileComponent } from "./alerting-profile/alerting-profile.component";
import { AlertingProfilesListComponent } from "./alerting-profiles-list/alerting-profiles-list.component";
import { AlertingProfileFormComponent } from "./alerting-profile-form/alerting-profile-form.component";

// Routes
import { EventsManagementRouting } from "./events-management.routing";
import { EditRuleDialogComponent } from "./edit-rule-dialog/edit-rule-dialog.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgxSliderModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        EventsManagementRouting,
        MatDialogModule,
        TourMatMenuModule,
        MatTooltipModule,
        MatMenuModule
    ],
    declarations: [
        AlertingProfileComponent,
        AlertingProfilesListComponent,
        AlertingProfileFormComponent,
        EditRuleDialogComponent
    ]
})
export class EventsManagementModule {}
